<template>
  <BackofficeBase :loader_prop="loader_prop"> 
    <h1>?האם אתה בטוח שברצונך למחוק יחידה זו</h1>
        <router-link
      :to="{
        path: '/backoffice/unit_delete',
        query: {id},
      }"
      >מחיקה
    </router-link>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";

export default {
  name: "BackofficeUnitDeleteWarning",
  components: {
    BackofficeBase,
  },
  data() {
    return {
      loader_prop: true,
      view_data: [],
    };
  },
  async mounted() {
    this.id = this.$route.query.id;
// delete_unit
    this.loader_prop = false;
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
</style>

